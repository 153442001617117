import React from "react"
import { Link } from "gatsby"
import { DEREK_EMAIL } from "../constants"
import Layout from "../components/layout/layout"

export default function NotFound() {
  return (
    <Layout>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <br />
            <p>This page doesn't exist</p>
            <br />
            <p>
              Please go to the <Link to="/">homepage</Link>, or contact me at{" "}
              <a href={`mailto:${DEREK_EMAIL}`}>{DEREK_EMAIL}</a>
            </p>
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}
